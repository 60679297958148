<template>
  <div class="flex flex-col items-center py-4">
    <div class="w-full lg:px-36 sm:px-8">
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
          <strong>Usuarios y Permisos</strong>
        </h1>
      </div>
      <div class="flex flex-col space-y-4">
        <content-card v-loading.fullscreen.lock="sendingInvitation">
          <div class="w-full py-4">
            <div class="w-full px-4 lg:px-8 space-y-2 text-center">
              <strong>Enviar invitación por correo</strong>
              <div class="flex">
                <el-input v-model="email" />
                <div class="ml-4">
                  <el-button @click="sendInvite">Enviar</el-button>
                </div>
              </div>
              <div class="flex mt-2">
                <el-radio-group
                  v-model="accountType">
                  <el-radio :label="AccountTypeEnum.Technical">Técnico</el-radio>
                  <el-radio :label="AccountTypeEnum.Biller">Recepcionista</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </content-card>
        <content-card v-loading.fullscreen.lock="changingRol || changingPermit">
          <div class="w-full py-4">
            <div class="w-full">
              <div class="block w-full overflow-x-auto">
                <div class="w-full flex flex-row border-collapse">
                  <div class="w-1/12 font-bold text-left"></div>
                  <div class="w-6/12 font-bold text-left">Nombre</div>
                  <div class="w-5/12 font-bold text-left">Rol</div>
                </div>
                <div
                  v-for="user in users"
                  :key="user.id"
                  class="w-full flex flex-row border-b">
                  <div class="w-1/12 text-left">
                    <div class="w-12/12 py-2 flex flex-row">
                      <i @click="showDetails(user.id)" class="text-lg cursor-pointer bx"
                         :class="details[user.id] ? 'bx-chevron-down' : 'bx-chevron-right'"></i>
                    </div>
                  </div>
                  <div class="w-11/12 text-left">
                    <div class="w-12/12 py-2 flex flex-row">
                      <div class="w-6/12 text-left">
                        {{ user.name }}
                      </div>
                      <div class="w-5/12 text-left">
                        <el-select
                          v-model="user.accountType"
                          @change="updateUserAccountType(user.id, $event)"
                          :disabled="user.accountType === AccountTypeEnum.Owner">
                          <el-option
                            v-for="accountType in accountTypes"
                            :key="accountType.value"
                            :value="accountType.value"
                            :label="accountType.label"
                            :disabled="accountType.disabled">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div v-if="details[user.id]" class="py-2">
                      <div class="w-full">
                        <div class="w-full py-1 flex flex-col lg:flex-row">
                          <div class="w-full lg:w-5/12">
                            <label
                              :class="{ 'cursor-pointer': !user.isOwner }"
                              :for="`permit-can-view-administration-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá hacer uso de todo el panel de administrar negocio"></i>
                              Permiso para usar panel de administración
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-view-administration-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canViewAdministration"
                              @change="changePermit(user, 'canViewAdministration')"/>
                          </div>
                          <div class="w-full lg:w-5/12">
                            <label :class="{ 'cursor-pointer': !user.isOwner }" :for="`permit-can-view-branch-offices-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá tener acceso a las demás sucursales"></i>
                              Permiso para uso de panel de sucursales
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-view-branch-offices-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canViewBranchOffices"
                              @change="changePermit(user, 'canViewBranchOffices')"/>
                          </div>
                        </div>
                        <div class="w-full py-1 flex flex-col lg:flex-row">
                          <div class="w-full lg:w-5/12">
                            <label :class="{ 'cursor-pointer': !user.isOwner }" :for="`permit-can-view-statistics-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá ver las estadísticas del negocio"></i>
                              Permiso para usar panel de estadísticas
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-view-statistics-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canViewStatistics"
                              @change="changePermit(user, 'canViewStatistics')"/>
                          </div>
                          <div class="w-full lg:w-5/12">
                            <label :class="{ 'cursor-pointer': !user.isOwner }" :for="`permit-can-view-orders-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá ver todas las ordenes, no solo las ordenes asignadas a él"></i>
                              Permiso para ver ordenes de todo el negocio
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-view-orders-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canViewOrders"
                              @change="changePermit(user, 'canViewOrders')"/>
                          </div>
                        </div>
                        <div class="w-full py-1 flex flex-col lg:flex-row">
                          <div class="w-full lg:w-5/12">
                            <label :class="{ 'cursor-pointer': !user.isOwner }" :for="`permit-can-edit-orders-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá editar las ordenes"></i>
                              Permiso para modificar ordenes
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-edit-orders-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canEditOrders"
                              @change="changePermit(user, 'canEditOrders')"/>
                          </div>
                          <div class="w-full lg:w-5/12">
                            <label :class="{ 'cursor-pointer': !user.isOwner }" :for="`permit-can-edit-prices-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá modificar los precios y costos si no solo vera precio final"></i>
                              Permiso para editar y ver costo y precio
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-edit-prices-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canEditOrdersPrices"
                              @change="changePermit(user, 'canEditOrdersPrices')"/>
                          </div>
                        </div>
                        <div class="w-full py-1 flex flex-col lg:flex-row">
                          <div class="w-full lg:w-5/12">
                            <label :class="{ 'cursor-pointer': !user.isOwner }" :for="`permit-can-edit-quantities-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá modificar la cantidad de el inventario"></i>
                              Permiso para editar el stock de productos
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-edit-quantities-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canEditStockQuantities"
                              @change="changePermit(user, 'canEditStockQuantities')"/>
                          </div>
                          <div class="w-full lg:w-5/12">
                            <label :class="{ 'cursor-pointer': !user.isOwner }" :for="`permit-can-edit-prices-${user.id}`">
                              <i class="bx bx-info-circle" title="El usuario podrá modificar los precios y costos si no solo vera precio final"></i>
                              Permiso para crear órdenes
                            </label>
                          </div>
                          <div class="w-full lg:w-1/12">
                            <el-switch
                              :id="`permit-can-edit-quantities-${user.id}`"
                              :disabled="user.isOwner"
                              v-model="user.canCreateOrders"
                              @change="changePermit(user, 'canCreateOrders')"/>
                          </div>
                        </div>
                      </div>
                      <div class="w-full">
                        <div class="w-full" style="text-align: right;">
                          <el-button
                            class="el-button--primary mt-8"
                            @click="save">
                            Guardar
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </content-card>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import { Regex } from '@/utils/emails';
import { AccountTypeEnum, PermissionEnum } from '@/constants';
import AdminService from '@/services/AdminService';
import { ACCOUNT_REPLACE_PERMITS } from '@/store/modules/account/mutation-types';

export default {
  name: 'UsersAndPermissions',
  mixins: [
    error,
  ],
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard'),
  },
  mounted() {
    this.loadUsers();
  },
  data() {
    return {
      sendingInvitation: false,
      changingPermit: false,
      changingRol: false,
      details: {},
      users: [],
      accountTypes: [
        { value: AccountTypeEnum.Owner, label: 'Dueño', disabled: true },
        { value: AccountTypeEnum.Technical, label: 'Técnico', disabled: false },
        { value: AccountTypeEnum.Biller, label: 'Recepcionista', disabled: false },
      ],
      email: undefined,
      accountType: AccountTypeEnum.Technical,
      AccountTypeEnum,
      errorMessage: undefined,
    }
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    showDetails(userId) {
      this.details = {
        ...this.details,
        [userId]: !this.details[userId],
      }
    },
    save() {
      this.$toastr.s('Se actualizo con exito');
    },
    async loadUsers() {
      try {
        const users = await AdminService.UsersAndPermissions.getUsers();
        this.users = users.map((u) => ({
          id: u.id,
          accountType: u.accountType,
          name: u.fullname,
          isOwner: u.isOwner,
          canViewAdministration: u.canViewAdministration || false,
          canViewBranchOffices: u.canViewBranchOffices || false,
          canViewStatistics: u.canViewStatistics || false,
          canViewOrders: u.canViewOrders || false,
          canEditOrders: u.canEditOrders || false,
          canEditOrdersPrices: u.canEditOrdersPrices || false,
          canEditStockQuantities: u.canEditStockQuantities || false,
          canCreateOrders: u.canCreateOrders || false,
      }));
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
    async updateUserAccountType(userId, accountType) {
      this.changingRol = true;
      try {
        await AdminService.UsersAndPermissions.updateAccountType(userId, { accountType });
        this.$toastr.s('Se actualizo con exito');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.changingRol = false;
      }
    },
    async sendInvite() {
      this.sendingInvitation = true;
      try {
        if (!Regex.test(this.email)) {
          this.errorMessage = 'Formato de correo electronico invalido';
          return;
        }
        await AdminService.UsersAndPermissions.sendInvite({ email: this.email.toLowerCase(), accountType: this.accountType });
        this.email = undefined;
        this.accounType = AccountTypeEnum.Technical;
        this.$toastr.s('Invitacion enviada con exito');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.sendingInvitation = false;
      }
    },
    async changePermit(user, permit) {
      this.changingPermit = true;
      try {
        const data = {
          accountId: user.id,
          permission: PermissionEnum[permit],
          value: user[permit],
        };
        await AdminService.UsersAndPermissions.updatePermit(data);
        await this.$store.dispatch(
          ACCOUNT_REPLACE_PERMITS,
          {
            ...this.$store.state.account,
            [permit]: user[permit],
          },
        );
      } catch (e) {
        user[permit] = !user[permit];
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.changingPermit = false;
      }
    },
  },
}
</script>
